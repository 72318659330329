<script>
import {
  FacebookIcon,
  TwitterIcon,
  InstagramIcon,
  LinkedinIcon
} from "vue-feather-icons";
export default {
  components: {
    FacebookIcon,
    TwitterIcon,
    InstagramIcon,
    LinkedinIcon
  }
};
</script>

<template>
  <div style="position: relative;">
    <!-- Footer Start -->
    <section class="footer" style="background-image: url(assets/images/footer-bg.png)">
      <div class="container">
        <div class="row">
          <div class="col-lg-5">
            <div class="mb-5">
              <img src="@/assets/images/logo-light.png" alt class height="42" />
              <p class="text-white-50 my-4 font-size-15">
                Aplicación 100% en línea diseñada para la administración de condominios y/o edificios de diversas indoles (Residenciales, Centros Comerciales, Empresariales, etc).
              </p>
              <ul class="list-inline footer-social-icon-content">
                <li class="list-inline-item mr-4">
                  <a href="javascript: void(0);" class="footer-social-icon">
                    <facebook-icon></facebook-icon>
                  </a>
                </li>
                <li class="list-inline-item mr-4">
                  <a href="javascript: void(0);" class="footer-social-icon">
                    <twitter-icon></twitter-icon>
                  </a>
                </li>
                <li class="list-inline-item mr-4">
                  <a href="javascript: void(0);" class="footer-social-icon">
                    <instagram-icon></instagram-icon>
                  </a>
                </li>
                <!-- <li class="list-inline-item">
                  <a href="javascript: void(0);" class="footer-social-icon">
                    <linkedin-icon></linkedin-icon>
                  </a>
                </li> -->
              </ul>
            </div>
          </div>
          <div class="col-lg-6 offset-lg-1">
            <div class="row">
              <div class="col-md-6">
                <h6 class="text-white text-uppercase mb-3">Contenido de Interés</h6>
                <ul class="list-unstyled footer-sub-menu">
                  <li>
                    <a href="/publicaciones" class="footer-link">Publicaciones</a>
                  </li>
                  <li>
                    <a href="https://soporte.condominiosvenezuela.com/" target="_blank" class="footer-link">Base de conocimientos</a>
                  </li>
                  <!-- <li>
                    <a href="javascript: void(0);"  v-scroll-to="{ el:'#service', offset: -2}" class="footer-link">Inversiones</a>
                  </li>
                  <li>
                    <a href="javascript: void(0);" class="footer-link">Misión</a>
                  </li> -->
                </ul>
              </div>
              <div class="col-md-6">
                <h6 class="text-white text-uppercase mb-3">Póliticas</h6>
                <ul class="list-unstyled footer-sub-menu">
                  <li>
                    <a href="/terminos" class="footer-link">Términos de uso</a>
                  </li>
                  <li>
                    <a href="/privacidad" class="footer-link">Aviso de privacidad</a>
                  </li>
                  <!-- <li>
                    <a href="javascript: void(0);" v-scroll-to="{ el:'#referidos', offset: -2}" class="footer-link">Referidos</a>
                  </li>
                  <li>
                    <a href="javascript: void(0);" v-scroll-to="{ el:'#pricing', offset: -2}" class="footer-link">Planes</a>
                  </li> -->
                </ul>
              </div>
              <!-- <div class="col-md-4">
                <h6 class="text-white text-uppercase mb-3">Soporte</h6>
                <ul class="list-unstyled footer-sub-menu">
                  <li>
                    <a href="javascript: void(0);" class="footer-link">FAQs</a>
                  </li>
                  <li>
                    <a href="javascript: void(0);" class="footer-link">Servicio al Cliente</a>
                  </li>
                  <li>
                    <a href="javascript: void(0);" class="footer-link" v-b-modal.register-modal>Registrate</a>
                  </li>
                  <li>
                    <a href="javascript: void(0);" class="footer-link">Guía</a>
                  </li>
                </ul>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    <div class="footer__animation">
        <img src="@/assets/images/footer__home___illustration.png" alt="Home" class="home__illustration">
    </div>
    </section>
    <div class="footer-alt py-3">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p
                class="text-white-50 font-size-15 mb-0"
              >{{ new Date().getFullYear()}} © <a href="https://www.condominiosvenezuela.com/" class="text-muted">SyS, C.A. (Suministros y Soluciones Informaticas de Venezuela, C.A. | RIF.: J-401582257)</a> | Todos los derechos reservados.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer End -->
  </div>
</template>